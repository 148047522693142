<!-- 开票资料弹窗 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'开票资料':'开票资料'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <el-form-item label="公司名称">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="开票税号">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="开户银行">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="银行账户">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="电话号码">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="企业地址">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="邮箱地址">
        <el-input
          placeholder="请输入"
          v-model="form.input"
          clearable>
        </el-input>
      </el-form-item>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>

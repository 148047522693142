<!-- 更新签约信息弹窗 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'更新签约信息':'更新签约信息'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">
      <el-row :gutter="18">
        <el-col :span="24">
          <el-form-item label="生效日期:" prop="nickname">
            <el-date-picker
                v-model="form.value1"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="签约周期:" prop="nickname">
            <el-input placeholder="请输入" v-model="form.zhouqi">
              <template slot="append">.年</template>
            </el-input>
          </el-form-item>
          <el-form-item label="自动续签:" prop="nickname">
            <el-select
                clearable
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="全部" value="1"/>
              <el-option label="启用" value="2"/>
              <el-option label="未启用" value="3"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!--上传合同附件-->
      <el-row :gutter="18">
        <div class="zidingyishangchuan">
          <el-col :span="24" style="display: flex">
            <span style="font-size: 14px;font-weight: 700;text-align: right;color: #4e4e4e; margin-top: 8px;">上传合同附件：</span>
            <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="fileList">
              <div class="shangchuan" style="margin-left: 20px">
                <el-button class="custom-button">
                  <i class="el-icon-shangchuan" />
                  <span style="vertical-align: middle" @click="Showageing()">点击上传</span>
                </el-button>
              </div>
              <div slot="tip" class="el-upload__tip" style="margin-left: 20px;">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-col>
        </div>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 上传假数据展示
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    //上传事件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
</style>

<template>
  <!--合同信息 模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <el-form
            :model="where"
            label-width="60px"
            class="ele-form-search"
            @submit.native.prevent>
          <el-row :gutter="15">
            <el-col :lg="21" :md="12">
              <el-form-item label="服务商:">
                <el-select
                    style="width: 300px !important;"
                    clearable
                    v-model="where.gender"
                    placeholder="请选择服务商"
                    class="ele-fluid">
                  <el-option label="服务商1" value="1"/>
                  <el-option label="服务商1" value="2"/>
                  <el-option label="服务商 1" value="3"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="zhanshi" style="margin-top: 15px;">
        <el-row :gutter="15">
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">生效日期：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">2023-07-24</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约周期：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">1年</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">到期自动签续：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">是</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约状态：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">
              <span style="display: inline-block; width: 8px;height: 8px;background: #25eb61;border-radius: 50%; vertical-align: middle"></span>
              <span style="vertical-align: middle; margin-left: 5px;">启用中</span>
            </span>
          </el-col>
        </el-row>
      </div>

      <!--开票资料-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              开票资料
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="Showageing()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" >新增开票资料</span>
              </el-button>
            </div>
          </div>
        </div>
        <!-- 开票资料表格 -->
        <div style="margin-top: 15px;">
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" @click="Showageing(scope.row)">编辑</el-link>
              <el-link :underline="false" @click="del(scope.row)">删除</el-link>
            </template>
          </my-table>
        </div>
      </div>

      <!--签约记录-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              签约记录
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button @click="openEdit()">更新签约</el-button>
            </div>
          </div>
        </div>
        <!-- 签约记录表格 -->
        <div style="margin-top: 15px;">
          <my-table
              ref="myTable"
              :columns="columns1"
              :tableData="List1"
              :headerCellStyle="headerCellStyle"
              :total="total1"
              :customsFromWhere="where1"
              :loading="loading1"
              @select="select1"
              @ChangeSize="ChangeSize1"
              @currentChange="currentChange1"
          >
            <!-- 不使用数据可不接收scope值 -->
            <!--<template v-slot:operationSlot= "{ scope }">-->
            <!--  <el-link :underline="false" type="warning">查看</el-link>-->
            <!--</template>-->
            <template v-slot:operationSlot= "">
              <el-link :underline="false" type="warning">查看</el-link>
            </template>
          </my-table>
        </div>
      </div>


    </el-card>

    <!--开票资料弹窗-->
    <Edit :data="currenta" :visible.sync="showEdita"/>

    <!-- 编辑弹窗 -->
    <subscription-info :data="current" :visible.sync="showEdit"/>

  </div>
</template>

<script>
// 引入更新签约弹窗
import SubscriptionInfo from './components/subscription-info.vue';
import Edit from './components/edit.vue'

export default {
  components:{
    SubscriptionInfo,
    Edit
  },
  data(){
    return{
      //条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 水印配置表单数据
      form:{
        name:'',
      },

      // 示例图
      img: require('../../../../../assets/images/home/jine.png'),

      // 开票资料数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,

      currenta:null,
      showEdita:false,

      // 表格数据
      List: [
        {
          id:'1',
          facilitator_code:'海信集团有限公司',
          facilitator_name: "1564210",
          facilitator_addres:'中国工商银行北京东升路支行',
          zhanghao:' 0200006209026400229',
          phone:'17845213650',
          dizhi:'北京市海淀区学院路37号',
          dizhi1:'北京市海淀区学院路37号'
        }
      ],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "公司名称",
          prop: "facilitator_code",
          isShow: true
        },
        {
          label: "开票税号",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "开户银行",
          prop: "facilitator_addres",
          isShow: true
        },
        {
          label: "银行账号",
          prop: "zhanghao",
          isShow: true
        },
        {
          label: "电话",
          prop: "phone",
          isShow: true
        },
        {
          label: "企业地址",
          prop: "dizhi",
          isShow: true
        },
        {
          label: "邮寄地址",
          prop: "dizhi1",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 签约表格数据
      where1:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      List1: [
        {
          id:'1',
          date:'2023-07-24',
          zhouqi: "1年",
          xuqian:'是',
          shijian:' 2023-12-14 12:30:54',
          ren:'林侃',
        }
      ],
      // 总条目数
      total1: 0,
      //加载动画
      loading1:false,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "生效日期",
          prop: "date",
          isShow: true
        },
        {
          label: "签约周期",
          prop: "zhouqi",
          isShow: true
        },
        {
          label: "是否自动续签",
          prop: "xuqian",
          isShow: true
        },
        {
          label: "操作时间",
          prop: "shijian",
          isShow: true
        },
        {
          label: "操作人",
          prop: "ren",
          isShow: true
        },
        {
          label: "合同附件",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

    }
  },

  methods:{
    Showageing(row){
      this.currenta = row;
      this.showEdita = true;
    },

    // 点击更新签约按钮
    openEdit(){
      this.current = {};
      this.showEdit = true;
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },

    //签约记录表格事件
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val)
      this.where1.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize1(val){
      console.log('当前页面显示的条数',val);
      this.where1.limit = val;
      this.getList()
    },


  }

}
</script>

<style lang="scss" scoped>
.ageing{
  .ageing_b{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    margin-top: 15px;
  }

  .ageing_b_text_l{
    display: inline-block;
    width: 160px;
    font-size: 16px;
    font-weight: 400;
    color: #868792;
    text-align: right;
  }
  .ageing_b_text_r{
    margin-left: 15px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #050505;
  }
}

// 左右并行
.parallel:after{
  content: '';
  clear: both;
  display: block;
}
.parallel{
  .left{
    width: 30%;
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #050505;
    margin-top: 5px;
  }
  .right{
    width: 50%;
    float: left;
    margin-left: 15px;
  }
}

.right_img{
  width: 300px;
  height: 280px;
  padding: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


</style>
